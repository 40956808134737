import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
import firebase from "firebase/compat/app";

import LibraryBooks from "@/components/LibraryBooks.vue";
import BooksPage from "@/components/BooksPage.vue";

import AuthorBooks from '@/components/AuthorBooks.vue';


Vue.use(VueRouter);
const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /* {
    path: "/",
    name: "typography",
    component: () => import("@/views/typography/Typography.vue"),
  },
  {
    path: "/typography",
    name: "typography",
    component: () => import("@/views/typography/Typography.vue"),
  },
    {
      path: "/icons",
      name: "icons",
      component: () => import("@/views/icons/Icons.vue"),
    },
    */
    {
      path: "/",
      name:"home",
      component: ()=> import("@/views/pages/Home.vue"),
      meta: { hideSidebar: true }
    },
    {
      path: "/Level",
      name: "Level",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/Level.vue"),
    },
    {
      path: "/Library",
      name: "Library",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/Library.vue"),
    },
    
    {
    path: "/CreateLibrary",
      name: "CreateLibrary",
      meta: {
        requiresAdmin: true,hideSidebar: true,
      },
      component: () => import("@/components/CreateLibrary.vue"),
    },
    {
      path: "/myLibrary",
      name: "my library",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/components/UserLibrary.vue"),
    },
    {
      path: "/livraison",
      name: "livraison",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/components/UserLivraison.vue"),
    },
    
    {
      path: "/monlivre",
      name: "mon livre",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/components/UserLivre.vue"),
    },
    {
      path: "/aide",
      name: "aides",
      meta: {
        requiresAdmin: false,
      },
      component: () => import("@/components/UserAide.vue"),
    },
    {
      path: "/mesmessages",
      name: "mes messages",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/components/UserMessage.vue"),
    },
    
    {
      path: "/subscription",
      name: "Subscription",
      meta: {
        requiresAdmin: true,
      },
      component: () =>
        import("@/views/pages/Subscription/subscriptionList.vue"),
    },
    {
      path: "/subscriptionUsers",
      name: "subscriptionUsers",
      meta: {
        requiresAdmin: true,
      },
      component: () =>
        import("@/views/pages/Subscription/subscriptionUsers.vue"),
    },
    {
      path: "/EditVerset/:versetId",
      name: "EditVerset",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/EditVerset.vue"),
    },
    {
      path: "/Surat",
      name: "Surat",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Surat.vue"),
    },
    {
      path: "/EditActuality/:id",
      name: "EditActuality",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Actuality/EditActuality.vue"),
    },
    {
      path: "/Actuality",
      name: "Actuality",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Actuality/Actuality.vue"),
    },
    {
      path: "/complaint",
      name: "Complaint",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Complaint/Complaint.vue"),
    },
    {
      path: "/faq",
      name: "Faq",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Faq/faq.vue"),
    },
    {
      path: "/ActualityCategorie/:category/:id",
      name: "ActualityCategorie",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Actuality/ActualityCategorie.vue"),
    },
    {
      path: "/LibraryUser",
      name: "Recitateur",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/LibraryUser.vue"),
    },
    {
      path: "/EditRecitateur/:id",
      name: "EditRecitateur",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/EditRecitateur.vue"),
    },

    {
      path: "/VersetStartTimeSurat/:suratId/:recitateurId",
      name: "VersetStartTime",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/VersetStartTime.vue"),
    },
    {
      path: "/EditVersetStartTime/:versetId/:recitateurId",
      name: "EditVersetStartTime",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/EditVersetStartTime.vue"),
    },
    {
      path: "/PublishHouse",
      name: "SousTheme",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/SousTheme/PublishHouse.vue"),
    },
    {
      path: "/PublishHouseU",
      name: "publish house",
      meta: {
         requiresAuth: true, requiredRole: 'LIBRARY' 
      },
      component: () => import("@/views/pages/SousTheme/PublishHouseUser.vue"),
    },

    {
      path: "/Book",
      name: "SousVersetList",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Book.vue"),
    },
    {
      path: "/Admins",
      name: "Admins",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/users/Admins.vue"),
    },
    {
      path: "/User",
      name: "User",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/users/User.vue"),
    },
    {
      path: "/NewUser",
      name: "NewUser",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/users/NewUser.vue"),
    },

    {
      path: "/EditSousTheme:id",
      name: "EditSousTheme",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/SousTheme/EditSousTheme.vue"),
    },

    {
      path: "/Category",
      name: "Category",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Category/Category.vue"),
    },
    {
      path: "/Books",
      name: "Books",
      meta: { hideSidebar: true},
      component: () => import("@/views/pages/Books.vue"),
    },
    {
      path: "/Parametre",
      name: "Parametre",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Level/Parametre.vue"),
    },
    {
      path: "/PayementData",
      name: "UserPayementData",
      meta: {  requiresAdmin: true,},
      component: () => import("@/views/pages/UserPayementData.vue"),
    },
    /*
    {
      path: "/EditTheme:id",
      name: "EditTheme",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Category/EditTheme.vue"),
    },
    */
    {
      path: "/Author",
      name: "Auteur",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/Auteur.vue"),
    },
    {
      path: "/orderList",
      name: "UserOrderList",
      meta: {
        requiresAdmin: true,
      },
      component: () => import("@/views/pages/UserOrderList.vue"),
    },

    {
      path: '/library/:id',
      name: 'LibraryBooks',
      component: () => import("@/components/LibraryBooks.vue"),
      props: true, // Pass route params as props
      meta: { requiresAuth: true, requiredRole: 'LIBRARY' },
    },
    
    { path: '/author/:id/books', component: AuthorBooks, props: true },
    {
      path: '/books/:publishingHouseId',
      name: 'BooksPage',
      component: BooksPage,
      props: true,
    },
    /* {
      path: "/cards",
      name: "cards",
      component: () => import("@/views/cards/Card.vue"),
    },
    {
      path: "/simple-table",
      name: "simple-table",
      component: () => import("@/views/simple-table/SimpleTable.vue"),
    },
    {
      path: "/form-layouts",
      name: "form-layouts",
      component: () => import("@/views/form-layouts/FormLayouts.vue"),
    },
    {
      path: "/pages/account-settings",
      name: "pages-account-settings",
      component: () =>
        import("@/views/pages/account-settings/AccountSettings.vue"),
    },*/
    {
      path: "/Qorani/supressioncompte",
      name: "login",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        layout: "blank",
      },
    },
    
    {
      path: "/pages/register",
      name: "pages-register",
      component: () => import("@/views/pages/Register.vue"),
      meta: {
        layout: "blank",
      },
    },
    {
      path: "/pages/detail/:orderId",
      name: "DetailOrder",
      component: () => import("@/views/pages/DetailOrder.vue"),
      meta: {
        layout: "blank",
      },
    },
    /* {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },*/
  ],
});

 router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAdmin);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("login");
  } else {
    next();
  }
});


export default router;
