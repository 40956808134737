import Vue from "vue";
import Vuex from "vuex";
import Constant from "@/utils/constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    showLoading: false, 
    imageId:"", 
    imageIdBanner:"",
    showLoadingBanner:false,
    imageIds: [],
  },
  mutations: {
    setShowLoading(state, payload) {
      state.showLoading = payload;
    },
    setImageId(state, payload) {
      state.imageId = payload;
    },
    setImageIds(state, payload) {
      state.imageIds.push(payload); 
    },
    setShowLoadingBanner(state, payload) {
      state.showLoadingBanner = payload;
    },
    setImageIdBanner(state, payload) {
      state.imageIdBanner = payload;
    },
  },
  actions: {
    async uploadImageSuccessS({ commit }, { index, fileList }) {
      const imageName = fileList[index].name;

      const file = convertImagePathToFile(fileList[index]);
      const formDataToSend = new FormData();
      formDataToSend.append("file", file, imageName);

      try {
        commit('setShowLoading', true); 

        const response = await fetch(`${Constant.QORANI}fileDB/upload`, {
          method: "POST",
          body: formDataToSend,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error uploading image", response.status, "Response text:", errorText);
          commit('setShowLoading', false);
          return;
        }

        const responseData = await response.json();
        commit('setImageIds', responseData.id);  // Use setImageIds mutation to add image ID
        console.log("idImage store :", responseData.id);
        console.log("Image uploaded successfully:", responseData.message);
        commit('setShowLoading', false);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        commit('setShowLoading', false); 
      }
    },
    
    async uploadImageSuccess({ commit }, { index, fileList }) {
      const imageName = fileList[index].name;

      const file = convertImagePathToFile(fileList[index]);
      const formDataToSend = new FormData();
      formDataToSend.append("file", file, imageName);

      try {
        commit('setShowLoading', true); 

        const response = await fetch(`${Constant.QORANI}fileDB/upload`, {
          method: "POST",
          body: formDataToSend,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error uploading image", response.status, "Response text:", errorText);
          commit('setShowLoading', false);
          return;
        }

        const responseData = await response.json();
        commit('setImageId', responseData.id); 
        console.log("idImage store :", responseData.id);
        console.log("Image uploaded successfully:", responseData.message);
        commit('setShowLoading', false);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        commit('setShowLoading', false); 
      }
    },
    async uploadImageSuccessBanner({ commit }, { index, fileList }) {
      const imageName = fileList[index].name;

      const file = convertImagePathToFile(fileList[index]);
      const formDataToSend = new FormData();
      formDataToSend.append("file", file, imageName);

      try {
        commit('setShowLoadingBanner', true); 

        const response = await fetch(`${Constant.QORANI}fileDB/upload`, {
          method: "POST",
          body: formDataToSend,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error uploading imageBanner", response.status, "Response text:", errorText);
          commit('setShowLoadingBanner', false);
          return;
        }
        
        const responseData = await response.json();
        commit('setImageIdBanner', responseData.id); 
        console.log("idImageBanner store:", responseData.id);
        console.log("ImageBanner uploaded successfully:", responseData.message);
        commit('setShowLoadingBanner', false);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        commit('setShowLoadingBanner', false); 
      }
    },
  },

});

function convertImagePathToFile(element) {
  const byteString = atob(element.path.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ia], { type: "image/jpeg" });
  return new File([blob], element.name);
}


export default store;
